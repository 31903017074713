
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Utilities
import { Utilities } from '@app/shared/utilities';

// Component
import { ProposalDetailComponent } from '@app/proposals/proposal-detail/proposal-detail.component';

// Models
import { IProposalAreaItemModel, IProposalChangeOrderDataModel, IProposalChangeOrderListModel, IProposalDetailModel, IProposalFinancialsModel } from '@app/core/models/proposal.models';

// Enums
import { ChangeOrderItemActionEnum } from '@app/core/enums';


@Injectable()
export class ChangeOrderService {
	private readonly utils = inject( Utilities );

	public readonly changeTrigger$ = new BehaviorSubject( 0 );

	private _acceptedChangeOrders: IProposalChangeOrderListModel[];
	private _pendingChangeOrders : IProposalChangeOrderListModel[];

	private changeOrderFinancials: IProposalDetailModel['changeOrderFinancials'];

	private parentRef: ProposalDetailComponent;

	private toShowChangeOrders = new Set<number>();


	setComponentRef( ref: ProposalDetailComponent ): void {
		this.parentRef = ref;
	}


	get proposalId(): number {
		return this.parentRef?.proposalId;
	}


	get getParentClass(): ProposalDetailComponent {
		return this.parentRef;
	}


	setChangeOrders( { acceptedChangeOrders, pendingChangeOrders }: { acceptedChangeOrders: IProposalChangeOrderListModel[], pendingChangeOrders: IProposalChangeOrderListModel[] } ): void {
		this._acceptedChangeOrders = acceptedChangeOrders;
		this._pendingChangeOrders  = pendingChangeOrders;
	}


	setChangeOrderFinancials( changeOrderFinancials: IProposalDetailModel['changeOrderFinancials'] ): void {
		this.changeOrderFinancials = changeOrderFinancials;
	}


	get hasChangeOrders(): boolean {
		return !!( this._acceptedChangeOrders?.length || this._pendingChangeOrders?.length );
	}


	get allChangeOrders(): IProposalChangeOrderListModel[] {
		return [ ...this._acceptedChangeOrders, ...this._pendingChangeOrders ];
	}


	get acceptedChangeOrders(): IProposalChangeOrderListModel[] {
		return this._acceptedChangeOrders;
	}


	get pendingChangeOrders(): IProposalChangeOrderListModel[] {
		return this._pendingChangeOrders;
	}


	get isChangeOrder(): boolean {
		return this.parentRef?.isChangeOrder;
	}


	getChangeOrderInfo( changeOrderId: number ): { previewUrl: string, status: string, acceptedDate: Date, number: number } {

		const changeOrder = this._acceptedChangeOrders?.find( co => co?.id === changeOrderId ) || this._pendingChangeOrders?.find( co => co?.id === changeOrderId );

		if ( !changeOrder ) {
			return null;
		}

		return {
			previewUrl   : changeOrder.previewUrl,
			status       : changeOrder.status,
			acceptedDate : changeOrder.acceptedDate,
			number       : changeOrder.number,
		};
	}


	// Get change order data for area option id and change order id
	getChangeOrderData( areaId: number, areaOptionId: number, changeOrderId: number ): IProposalChangeOrderDataModel {

		const area            = this.parentRef?.proposal?.areas?.filter( a => a?.id === areaId )?.[0];
		const option          = area?.options?.filter( o => o?.id === areaOptionId )?.[0];
		const changeOrderData = option?.changeOrderData?.[changeOrderId];

		return changeOrderData;
	}


	// Get change order items for each item (by matching referenceItemId)
	getChangeOrderItems( areaId: number, areaOptionId: number, changeOrderId: number, referenceId: number ): IProposalAreaItemModel[] {

		const changeOrderData  = this.getChangeOrderData( areaId, areaOptionId, changeOrderId );
		const changeOrderItems = changeOrderData?.items?.filter( i => i?.referenceLineItemId === referenceId );

		if ( this.toShowChangeOrders.has( changeOrderId ) ) {
			return changeOrderItems;
		}

		return [];
	}


	showChangeOrderData( changeOrderId: number ): boolean {
		return this.toShowChangeOrders.has( changeOrderId );
	}


	updateToShowChangeOrders( changeOrderIds: number[] ): void {
		this.toShowChangeOrders.clear();

		changeOrderIds?.forEach( id => this.toShowChangeOrders.add( id ) );

		// Update change trigger to trigger change detection
		this.changeTrigger$.next( this.changeTrigger$.getValue() + 1 );
	}


	getCheckedChangeOrderData( areaId: number, areaOptionId: number ): IProposalChangeOrderDataModel[] {
		const checkedChangeOrderIds = Array.from( this.toShowChangeOrders.keys() ).sort();	// Sort to show based on change order ids

		const model: IProposalChangeOrderDataModel[] = [];

		if ( checkedChangeOrderIds?.length ) {
			for ( const changeOrderId of checkedChangeOrderIds ) {
				const data = this.getChangeOrderData( areaId, areaOptionId, changeOrderId );
				if ( data ) {
					model.push( data );
				}
			}
		}

		return model;
	}


	// TODO: NOT IN USE ANYMORE
	getCheckedChangeOrderAddedItems( areaId: number, areaOptionId: number ): IProposalAreaItemModel[] {
		const checkedChangeOrderData = this.getCheckedChangeOrderData( areaId, areaOptionId );

		const addedItems: IProposalAreaItemModel[] = [];

		if ( checkedChangeOrderData?.length ) {
			for ( const changeOrder of checkedChangeOrderData ) {
				const items = changeOrder?.items?.filter( i => i?.changeOrderActionType === ChangeOrderItemActionEnum.Added ) || [];

				addedItems.push( ...items );
			}
		}

		return addedItems;
	}


	getCheckedChangeOrderMiscItems( areaId: number, areaOptionId: number ): { changeOrderId: number, changeOrderNumber: number, items: IProposalAreaItemModel[] }[] {
		const checkedChangeOrderData = this.getCheckedChangeOrderData( areaId, areaOptionId );

		const model: { changeOrderId: number, changeOrderNumber: number, items: IProposalAreaItemModel[] }[] = [];

		if ( checkedChangeOrderData?.length ) {
			for ( const changeOrder of checkedChangeOrderData ) {
				// Grab checked change order misc items which are parent items only
				const items = changeOrder?.items?.filter( i => i?.referenceLineItemId === null && i?.parentId === 0 ) || [];

				model.push( { changeOrderId: changeOrder?.id, changeOrderNumber: changeOrder?.changeOrderNumber, items: items } );
			}
		}

		return model;
	}


	getCheckedChangeOrderAreaTotal( areaId: number, areaOptionId: number ): number {
		return this.getCheckedChangeOrderData( areaId, areaOptionId )?.reduce( ( acc, curr ) => acc + curr?.areaOptionTotal, 0 );
	}


	getCheckedChangeOrderRecurringServicesTotal( areaId: number, areaOptionId: number ): number {
		return this.getCheckedChangeOrderData( areaId, areaOptionId )?.reduce( ( acc, curr ) => acc + curr?.recurringServiceTotal, 0 );
	}


	getCheckedChangeOrderFinancials(): IProposalFinancialsModel {
		const checkedChangeOrderIds = Array.from( this.toShowChangeOrders.keys() );

		const model: IProposalFinancialsModel = {
			grandTotal                : null,
			partsTotal                : null,
			laborTotal                : null,
			feesTotal                 : null,
			partsValue                : null,
			discountAmount            : null,
			totalMsrpDiscount         : null,
			discountPercent           : null,
			discountType              : null,
			taxDetails                : { taxAmount: null, partsTotalTax: null, laborTotalTax: null, feesTotalTax: null, taxInformation: null },
			recurringServices         : { total: null, items: [] },
			projectTotal              : null,
			acceptedChangeOrdersTotal : null,
			projectBalance            : null,
			paidPaymentRequestTotal   : null,
			convenienceFees           : [],
			proposalTotalBeforeConvFee: null,
			projectTotalBeforeConvFee : null,
		};

		if ( checkedChangeOrderIds?.length ) {
			for ( const changeOrderId of checkedChangeOrderIds ) {
				const financials = this.changeOrderFinancials[ changeOrderId ];

				model.grandTotal               = this.getSumOfValueIfNotNull( model?.grandTotal, financials?.grandTotal );
				model.partsTotal               = this.getSumOfValueIfNotNull( model?.partsTotal, financials?.partsTotal );
				model.laborTotal               = this.getSumOfValueIfNotNull( model?.laborTotal, financials?.laborTotal );
				model.feesTotal                = this.getSumOfValueIfNotNull( model?.feesTotal, financials?.feesTotal );
				model.partsValue               = this.getSumOfValueIfNotNull( model?.partsValue, financials?.partsValue );
				model.discountAmount           = this.getSumOfValueIfNotNull( model?.discountAmount, financials?.discountAmount );
				model.totalMsrpDiscount        = this.getSumOfValueIfNotNull( model?.totalMsrpDiscount, financials?.totalMsrpDiscount );
				model.taxDetails.taxAmount     = this.getSumOfValueIfNotNull( model?.taxDetails?.taxAmount, financials?.taxDetails?.taxAmount );
				model.taxDetails.partsTotalTax = this.getSumOfValueIfNotNull( model?.taxDetails?.partsTotalTax, financials?.taxDetails?.partsTotalTax );
				model.taxDetails.laborTotalTax = this.getSumOfValueIfNotNull( model?.taxDetails?.laborTotalTax, financials?.taxDetails?.laborTotalTax );
				model.taxDetails.feesTotalTax  = this.getSumOfValueIfNotNull( model?.taxDetails?.feesTotalTax, financials?.taxDetails?.feesTotalTax );
				model.recurringServices.total  = this.getSumOfValueIfNotNull( model?.recurringServices?.total, financials?.recurringServices?.total );
			}
		}

		return model;
	}


	// Check if value2 is not null, if so, return value1 + value2, else return value2
	private getSumOfValueIfNotNull( value1: number, value2: number ): number {
		const result =
			this.utils.isValueDefined( value1 )
				? ( this.utils.isValueDefined( value2 )
					? ( value1 + value2 )
					: value2 )
				: value2;


		return result;
	}
}
